<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="block"
        >
          <a-form-item label='统计时间'>
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <!-- <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a> -->
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button icon="cloud-download" style="background-color: #909399;color: #fff;border-color: #909399;" @click="exportExcel" v-if="$root.$data.privateState.action.includes('/Customer/StaffCustomerListExport') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">导出当前结果</a-button>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.uuid"
        :dataSource="tableData"
        bordered
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </a-table>
    </div>
    <!-- table end -->
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import DownloadJS from 'downloadjs'
import screenfull from 'screenfull'

import { Customer } from '@/api'
import { aliveVM } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"Name":"","Phone":"","Status":"","Role":"","Type":"","CreatedAt":[]}'
const columns = [
  {
    title: '姓名',
    width: 150,
    dataIndex: 'name',
  },
  {
    title: '客户数量',
    width: 150,
    dataIndex: 'count',
  },
  // {
  //   title: '注册时间',
  //   dataIndex: 'createdAt',
  //   scopedSlots: { customRender: 'createdAt' },
  // },
  // {
  //   title: '操作',
  //   key: 'operation',
  //   width: 130,
  //   fixed: 'right',
  //   scopedSlots: { customRender: 'action' },
  // }
]
export default {
  name: 'CustomerStaffCustomerCount',
  mixins: [aliveVM],
  created () {
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
  },
  mounted () {
    this.getList()
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      screenfull,
      isFullscreen: false,
      expand: false,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      tableData: [],
      tableTdStyle: 'default',
      // 其他
      moment,
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.getList()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.getList()
    },
    async searchClick () {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'CreatedAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.getList()
    },
    // other
    async getList() {
        const res = await this.$axios
            .post(`${Customer}/StaffCustomerList`, {
              CreatedAt: this.searchParams && this.searchParams.CreatedAt ? this.searchParams.CreatedAt : null,
            })
            .catch(() => (this.fetching = false));
      if (!res.status) {
        this.$notification.error({ message: '获取数据错误', description: res.message })
        return
      }
      this.tableData = res.data
    },
    async exportExcel() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'CreatedAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.sending = true
      this.sendingHint = '正在获取数据'
      const apiRes = await this.$axios.post(`${Customer}/StaffCustomerListExport`, {
        CreatedAt: this.searchParams && this.searchParams.CreatedAt ? this.searchParams.CreatedAt : null,
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      let timeRange = '全部'
      if (this.searchParams && this.searchParams.CreatedAt) {
        timeRange = `${this.searchParams.CreatedAt[0]}至${this.searchParams.CreatedAt[1]}`
      }
      DownloadJS(window.atob(apiRes.data), `客户归属统计数据(${timeRange}).xlsx`)
    },
  }
}
</script>

<style>

</style>